// React
import React from "react";
import PropTypes from "prop-types";
// Helpers
import { map, range } from "@mefisto/utils";
// Framework
import { Grid } from "ui/components";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const FeedSkeletons = ({ skeleton, skeletonCount }) => (
  <>
    {map(range(skeletonCount), (key) => (
      <Grid item key={key}>
        {skeleton}
      </Grid>
    ))}
  </>
);

FeedSkeletons.propTypes = {
  skeleton: PropTypes.node,
  skeletonCount: PropTypes.number,
};

export default FeedSkeletons;
