// React
import React, { useContext } from "react";
import PropTypes from "prop-types";
// Components
import { ThemeContext } from "theme/core";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Themes = ({ defaultTheme = "light", children }) => {
  // Context
  const context = useContext(ThemeContext);
  context.defaultTheme = defaultTheme;
  // Render
  return <>{children}</>;
};

Themes.defaultProps = {
  __TYPE: "Themes",
};

Themes.propTypes = {
  defaultTheme: PropTypes.string,
  children: PropTypes.node,
};

export default Themes;
