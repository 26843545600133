// React
import React, { forwardRef, useState, useCallback } from "react";
import PropTypes from "prop-types";
// Framework
import { usePortal } from "stack";
import { UserInteraction } from "analytics";
import { classnames } from "ui/classnames";
import { makeStyles } from "ui/components";
import { Section } from "ui/section";
import { ExitToAppOutlined as LinkIcon } from "icon/material";
import { Link as Component } from "@material-ui/core";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    lineHeight: "normal",
  },
  rootColor: {
    color: theme.palette.text.link,
  },
  icon: {
    display: "inline-block",
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(-1) + 2,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const NavigationLink = forwardRef(
  (
    {
      context = "link",
      value,
      target = "internal",
      link,
      linkProps,
      icon,
      children,
      onHover,
      onSelection,
      // Deprecated
      onClick,
      ...props
    },
    ref
  ) => {
    // Styles
    const classes = useStyles();
    // Framework
    const { navigation } = usePortal();
    // State
    const [over, setOver] = useState(false);
    // Handlers
    const handleClick = useCallback(
      (...props) => {
        if (link) {
          if (target === "internal") {
            navigation.goTo(link, linkProps);
          }
          if (target === "external") {
            navigation.open(link);
          }
        }
        onClick?.(...props);
        onSelection?.(...props);
      },
      [navigation, target, link, linkProps, onClick, onSelection]
    );
    const handleMouseOver = useCallback(() => {
      setOver(true);
      onHover?.(true);
    }, [onHover]);
    const handleMouseLeave = useCallback(() => {
      setOver(false);
      onHover?.(false);
    }, [onHover]);
    return (
      <UserInteraction
        ref={ref}
        element="link"
        value={value}
        component={Component}
        componentProps={{
          onClick: handleClick,
          onMouseOver: handleMouseOver,
          onMouseLeave: handleMouseLeave,
          classes: {
            root: classnames(classes.root, {
              // Set default color when no color is defined
              [classes.rootColor]: !props.color,
            }),
          },
          ...props,
        }}
      >
        <Section context={context} value={value}>
          {children}
          {icon && over && (
            <LinkIcon color="inherit" className={classes.icon} />
          )}
        </Section>
      </UserInteraction>
    );
  }
);

NavigationLink.propTypes = {
  /**
   * Section context name.
   */
  context: PropTypes.string,
  /**
   * Element specification value.
   */
  value: PropTypes.string,
  /**
   * Defines target type.
   * If `internal` the link redirects to current domain.
   * If `external` the link opens new browser window/tab.
   */
  target: PropTypes.oneOf(["internal", "external"]),
  /**
   * Path passed to link.
   * When clicked the page is redirected to the link.
   */
  link: PropTypes.string,
  /**
   * Props passed to link.
   */
  linkProps: PropTypes.object,
  /**
   * The color of the link.
   */
  color: PropTypes.oneOf([
    "initial",
    "inherit",
    "primary",
    "secondary",
    "textPrimary",
    "textSecondary",
    "error",
  ]),
  /**
   * Icon added to link
   */
  icon: PropTypes.bool,
  /**
   * Controls when the link should have an underline.
   */
  underline: PropTypes.oneOf(["none", "hover", "always"]),
  /**
   * Applies the theme typography styles.
   */
  variant: PropTypes.string,
  /**
   * Called when user selects the link.
   */
  onSelection: PropTypes.func,
  /**
   * Called when user hovers over the link.
   * First param is set to `true` when the cursor is over the link and `false` when leaves the link.
   */
  onHover: PropTypes.func,
  /**
   * The content of the link.
   */
  children: PropTypes.node,
};

export default NavigationLink;
