// React
import React from "react";
// Framework
import { forwardType, Themes, LightTheme } from "@mefisto/web";

export default forwardType(Themes, () => (
  <Themes>
    <LightTheme
      theme={{
        palette: {
          custom: {
            red: "#e64a19",
            green: "#2ea66e",
            blue: "#1877f2",
            yellow: "#f9a825",
          },
          primary: {
            dark: "#ee9c24",
            main: "#f9a825",
            light: "#e79f50",
          },
          secondary: {
            dark: "#172127",
            main: "#1f292e",
            light: "#797979",
          },
        },
      }}
      overrides={(theme) => ({
        MuiButton: {
          containedPrimary: {
            color: theme.palette.text.primary,
          },
        },
      })}
    />
  </Themes>
));
