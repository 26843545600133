// React
import React, { memo } from "react";
import PropTypes from "prop-types";
// Framework
import { useTranslate } from "localization/hooks";
import { classnames } from "ui/classnames";
import { Button, Alert, AlertTitle } from "ui/components";
import { makeStyles } from "@material-ui/core";
import { SentimentDissatisfied as ErrorIcon } from "icon/material";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  center: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)",
  },
  cover: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
    transform: "none",
    zIndex: theme.zIndex.spinner,
    backgroundColor: theme.palette.common.white,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ErrorPlaceholder = ({
  display = "default",
  position = "default",
  icon,
  title,
  subtitle,
  actionTitle,
  onAction,
}) => {
  // Styles
  const classes = useStyles();
  // Framework
  const { translate } = useTranslate();
  // Render
  return (
    <Alert
      severity="error"
      action={
        onAction && (
          <Button noWrap onClick={onAction}>
            {actionTitle ?? translate("core:ui.errorPlaceholder.action")}
          </Button>
        )
      }
      icon={icon ?? <ErrorIcon />}
      className={classnames({
        [classes.cover]: display === "cover",
        [classes.center]: position === "center",
      })}
    >
      <AlertTitle>
        {title ?? translate("core:ui.errorPlaceholder.title")}
      </AlertTitle>
      {subtitle}
    </Alert>
  );
};

ErrorPlaceholder.propTypes = {
  display: PropTypes.oneOf(["default", "cover"]),
  position: PropTypes.oneOf(["default", "center"]),
  icon: PropTypes.element,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  action: PropTypes.string,
  onAction: PropTypes.func,
};

export default memo(ErrorPlaceholder);
