// React
import React, {
  memo,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
  Fragment,
} from "react";
import PropTypes from "prop-types";
// Helpers
import { map, size, isFunction } from "@mefisto/utils";
// Framework
import { useEntityListLazy } from "model/hooks";
import { EntityPropType } from "model/utils";
import { Feed } from "ui";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ModelFeed = forwardRef(
  (
    {
      entity: Entity,
      relation,
      input,
      resources,
      languages,
      pagination,
      fetchPolicy,
      nextFetchPolicy,
      tags,
      display,
      skeleton,
      skeletonCount,
      spacing,
      feedProps,
      emptyPlaceholderProps,
      children,
      loading: customLoading,
      onChange,
      onLoaded,
    },
    ref
  ) => {
    // Model
    const {
      called,
      error,
      data: list,
      fetch,
      fetchMore,
      loading,
      loadingMore,
    } = useEntityListLazy(Entity, {
      input,
      resources,
      languages,
      relation,
      pagination,
      fetchPolicy,
      nextFetchPolicy,
      tags,
    });
    // Ref
    useImperativeHandle(ref, () => ({
      async refresh() {
        return fetch();
      },
    }));
    // Effects
    useEffect(() => {
      fetch();
    }, [fetch]);
    useEffect(() => {
      onChange?.(list);
    }, [list, onChange]);
    useEffect(() => {
      console.log("ModelFeed: data loaded", called, !loading, !error);
      if (called && !loading && !error) {
        console.log("ModelFeed: data loaded", list);
        onLoaded?.(list);
      }
    }, [called, loading, error, list, onLoaded]);
    // Handlers
    const handleRefresh = useCallback(() => {
      fetch();
    }, [fetch]);
    const handleLoadMore = useCallback(() => {
      fetchMore();
    }, [fetchMore]);
    return (
      <Feed
        display={display}
        loading={!called || loading || customLoading}
        loadingMore={loadingMore}
        hasMore={list.hasMore}
        error={!!error}
        skeleton={skeleton}
        skeletonCount={skeletonCount}
        spacing={spacing}
        items={map(list.data, (data, key) => (
          <Fragment key={key}>
            {isFunction(children)
              ? children(data, {
                  index: key,
                  isFirst: key === 0,
                  isLast: key === size(list.data) - 1,
                })
              : children}
          </Fragment>
        ))}
        emptyDisabled={emptyPlaceholderProps?.disabled}
        emptyTitle={emptyPlaceholderProps?.title}
        emptySubtitle={emptyPlaceholderProps?.subtitle}
        onLoadMore={handleLoadMore}
        onRefresh={handleRefresh}
        {...feedProps}
      />
    );
  }
);

ModelFeed.propTypes = {
  /**
   * Model entity used for the feed
   */
  entity: EntityPropType,
  /**
   * Entity relation
   */
  relation: PropTypes.string,
  /**
   * Input data
   */
  input: PropTypes.object,
  /**
   * Resources data
   */
  resources: PropTypes.object,
  /**
   * List of requested languages
   */
  languages: PropTypes.array,
  /**
   * Pagination props
   */
  pagination: PropTypes.shape({
    pointer: PropTypes.string,
    limit: PropTypes.number,
    direction: PropTypes.oneOf(["ASC", "DESC"]),
    orderBy: PropTypes.string,
    equalTo: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string,
    ]),
  }),
  /**
   * Fetch policy of the list request
   */
  fetchPolicy: PropTypes.string,
  /**
   * Next fetch policy of the list request
   */
  nextFetchPolicy: PropTypes.string,
  /**
   * Custom tags used to list the data
   */
  tags: PropTypes.any,
  /**
   * Display mode. When `default` the feed will automatically load more
   * data when the end of the feed is reached. If set to `incremental`
   * a "load more" button will be shown to user instead. If set to `manual`
   * the user will have to call the `refresh` method to load more data.
   */
  display: PropTypes.oneOf(["default", "infinite", "incremental", "manual"]),
  /**
   * When set to `true` the skeleton loading is used instead of a spinner.
   */
  skeleton: PropTypes.node,
  /**
   * Number of skeleton items. Only applicable when skeleton is set to `true`.
   */
  skeletonCount: PropTypes.number,
  /**
   * Feed props
   */
  feedProps: PropTypes.object,
  /**
   * Empty placeholder data
   */
  emptyPlaceholderProps: PropTypes.shape({
    disabled: PropTypes.bool,
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }),
  /**
   * Children node or func. Use to display each feed item.
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /**
   * Called when data in feed change
   */
  onChange: PropTypes.func,
  /**
   * Called when data in feed loaded
   */
  onLoaded: PropTypes.func,
};

export default memo(ModelFeed);
