// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { Box } from "ui/components";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const TileFixedContent = ({ display = "fill", gutter, children }) => (
  <Box
    mx={gutter ? 0 : -3}
    mb={gutter ? 0 : -3}
    height={display === "fill" ? "100%" : "auto"}
  >
    {children}
  </Box>
);

TileFixedContent.propTypes = {
  display: PropTypes.oneOf(["fill", "fit"]),
  gutter: PropTypes.bool,
  children: PropTypes.node,
};

export default TileFixedContent;
