// React
import React from "react";
import PropTypes from "prop-types";
// Helpers
import { isEmpty, map } from "@mefisto/utils";
// Framework
import { Grid } from "ui/components";
// Components
import InfiniteList from "../InfiniteList";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const FeedInfiniteList = ({
  items,
  hasMore,
  contentProps,
  spacing,
  onLoadMore,
}) => (
  <InfiniteList
    isEmpty={isEmpty(items)}
    loadMore={onLoadMore}
    hasMore={hasMore}
    overflowX="hidden"
    contentProps={contentProps}
  >
    <Grid container direction="column" wrap="nowrap" spacing={spacing}>
      {map(items, (item, key) => (
        <Grid item key={key}>
          {item}
        </Grid>
      ))}
    </Grid>
  </InfiniteList>
);

FeedInfiniteList.propTypes = {
  items: PropTypes.any,
  hasMore: PropTypes.bool,
  contentProps: PropTypes.object,
  spacing: PropTypes.number,
  onLoadMore: PropTypes.func,
};

export default FeedInfiniteList;
