// React
import React, { forwardRef, useMemo } from "react";
import PropTypes from "prop-types";
// Helpers
import { isString, get } from "@mefisto/utils";
// Framework
import { makeStyles, classnames } from "ui";
// Components
import AvatarTooltip from "./components/AvatarTooltip";
import AnonymousAvatar from "./components/AnonymousAvatar";
import DeletedAvatar from "./components/DeletedAvatar";
import LetterAvatar from "./components/LetterAvatar";
import ImageAvatar from "./components/ImageAvatar";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: ({ background = theme.palette.background.surface.main }) => ({
    position: "relative",
    width: "fit-content",
    borderRadius: theme.radius.rounded,
    background,
  }),
  rootRegular: {
    border: theme.palette.border.overline.main,
  },
  rootHighlight: {
    border: theme.palette.border.overlineHighlight.main,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const SIZE = {
  small: 25,
  medium: 40,
  large: 70,
};

const Avatar = forwardRef(
  (
    {
      variant = "default",
      display = "regular",
      size = "medium",
      title,
      image,
      tooltip,
      background,
      className,
      style,
      children,
    },
    ref
  ) => {
    // Styles
    const classes = useStyles({ background });
    // Memo
    const sizeValue = useMemo(() => {
      return SIZE[size] ?? size;
    }, [size]);
    // Render
    return (
      <div
        ref={ref}
        style={style}
        className={classnames(className, classes.root, {
          [classes.rootRegular]: display === "regular",
          [classes.rootHighlight]: display === "highlight",
        })}
      >
        {(variant === "default" ||
          variant === "admin" ||
          variant === "disabled") && (
          <AvatarTooltip title={tooltip ? title : ""} placement="bottom">
            {isString(image) || get(image, "url") ? (
              <ImageAvatar image={image} title={title} size={sizeValue} />
            ) : (
              <LetterAvatar title={title} size={sizeValue} />
            )}
          </AvatarTooltip>
        )}
        {variant === "anonymous" && <AnonymousAvatar size={sizeValue} />}
        {variant === "deleted" && <DeletedAvatar size={sizeValue} />}
        {children}
      </div>
    );
  }
);

Avatar.propTypes = {
  variant: PropTypes.oneOf([
    "default",
    "admin",
    "anonymous",
    "deleted",
    "disabled",
  ]),
  display: PropTypes.oneOf(["regular", "highlight"]),
  size: PropTypes.oneOfType([
    PropTypes.oneOf(["small", "medium", "large"]),
    PropTypes.number,
  ]),
  title: PropTypes.string,
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      generation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      url: PropTypes.string,
    }),
  ]),
  tooltip: PropTypes.bool,
  background: PropTypes.string,
};

export default Avatar;
