// React
import React, { Children } from "react";
import PropTypes from "prop-types";
// Helpers
import { isEmpty, map, range } from "@mefisto/utils";
// Framework
import {
  makeStyles,
  ImageList,
  EmptyPlaceholder,
  ErrorPlaceholder,
  ImageListItem,
  Skeleton,
} from "ui/components";
import { classnames } from "ui/classnames";
import { useBreakpoint } from "ui/hooks";
import { useTheme } from "theme";
// Components
import CollectionComponent from "./components/CollectionComponents";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  gridListPadding: {
    padding: theme.spacing(1, 2, 2),
  },
  imageListItem: {
    // Overriding overflow, otherwise the tile shadow is not visible
    overflow: "inherit",
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Collection = ({
  columns = 4,
  gap = 16,
  scroll = "infinite",
  rowHeight = "auto",
  disablePadding,
  error,
  loading,
  loadingMore,
  hasMore,
  errorPlaceholderProps,
  emptyPlaceholderProps,
  skeletonProps,
  skeletonComponent,
  skeletonCount = 8,
  skeletonHeight = 180,
  children,
  onLoadMore,
  onRefresh,
}) => {
  // Styles
  const classes = useStyles();
  const theme = useTheme();
  // Framework
  const { breakpoint } = useBreakpoint(columns);
  // Render
  return (
    <CollectionComponent
      scroll={scroll}
      loading={loading}
      loadingMore={loadingMore}
      error={error}
      empty={isEmpty(children)}
      hasMore={hasMore}
      onLoadMore={onLoadMore}
    >
      <ImageList
        cols={breakpoint}
        gap={gap}
        rowHeight={rowHeight}
        className={classnames({
          [classes.gridListPadding]: !disablePadding,
        })}
      >
        {error ? (
          <ErrorPlaceholder
            position="center"
            onAction={onRefresh}
            {...errorPlaceholderProps}
          />
        ) : loading ? (
          map(range(skeletonCount), (key) => (
            <ImageListItem key={key} classes={{ item: classes.imageListItem }}>
              {skeletonComponent ?? (
                <Skeleton
                  variant="rect"
                  height={skeletonHeight}
                  borderRadius={theme.radius.large}
                  {...skeletonProps}
                />
              )}
            </ImageListItem>
          ))
        ) : isEmpty(children) ? (
          <EmptyPlaceholder position="center" {...emptyPlaceholderProps} />
        ) : (
          Children.map(children, (child) => (
            <ImageListItem classes={{ item: classes.imageListItem }}>
              {child}
            </ImageListItem>
          ))
        )}
      </ImageList>
    </CollectionComponent>
  );
};

Collection.propTypes = {
  /**
   * Number of columns or object with number of columns
   * based on the current breakpoint.
   */
  columns: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      xs: PropTypes.number,
      sm: PropTypes.number,
      md: PropTypes.number,
      lg: PropTypes.number,
      xl: PropTypes.number,
    }),
  ]),
  /**
   * Gap between items
   */
  gap: PropTypes.number,
  /**
   * Cell height in px. Set to 'auto' to let the children determine the height.
   */
  rowHeight: PropTypes.oneOfType([PropTypes.oneOf(["auto"]), PropTypes.number]),
  /**
   * Defines how the scrolling should work.
   * `infinite`: Using InfiniteList
   * `incremental`: Shows "Load More" button at the bottom
   * `manual`: No automatic scrollling available
   */
  scroll: PropTypes.oneOf(["infinite", "incremental", "manual"]),
  /**
   * Set to true when collection data is loading
   */
  loading: PropTypes.bool,
  /**
   * Pass error object
   */
  error: PropTypes.object,
  /**
   * Set to `true` if collection has more items than currently displayed
   */
  hasMore: PropTypes.bool,
  /**
   * Pass `error placeholder` props
   */
  errorPlaceholderProps: PropTypes.object,
  /**
   * Pass `empty placeholder` props
   */
  emptyPlaceholderProps: PropTypes.object,
  /**
   * Pass `skeleton` props
   */
  skeletonProps: PropTypes.object,
  /**
   * Pass different skeleton component
   */
  skeletonComponent: PropTypes.element,
  /**
   * Number of skeletons
   */
  skeletonCount: PropTypes.number,
  /**
   * Default skeleton height
   */
  skeletonHeight: PropTypes.number,
  /**
   * Items that will be in the image list.
   */
  children: PropTypes.node,
  /**
   * Called when collection is scrolled to `loadMore` area
   */
  onLoadMore: PropTypes.func,
  /**
   * Called when refresh is triggered
   */
  onRefresh: PropTypes.func,
};

export default Collection;
