// React
import React, { useCallback, useState } from "react";
// Helpers
import { isEmpty, get, toString } from "@mefisto/utils";
// Framework
import { useDeepMemo } from "hooks";
import { useTranslate } from "localization";
import { ClearOutlined as ClearIcon } from "icon/material";
import { NumberFormat } from "form/formatter";
import { Tooltip, IconButton, TextField, InputAdornment } from "ui";
// Components
import FormField from "../FormField";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Component = ({
  form: { setFieldValue, touched, errors, isSubmitting },
  field: { name, value },
  inputComponent,
  icon: Icon,
  disabled,
  label,
  helperText,
  placeholder,
  onChange,
  ...props
}) => {
  const fieldError = get(errors, name);
  const showError = get(touched, name) && !!fieldError;
  // Framework
  const { t } = useTranslate();
  // State
  const [hover, setHover] = useState(false);
  const [focus, setFocus] = useState(false);
  // Handlers
  const handleFocus = useCallback(() => {
    setFocus(true);
  }, []);
  const handleBlur = useCallback(() => {
    setFocus(false);
  }, []);
  const handleMouseEnter = useCallback(() => {
    setHover(true);
  }, []);
  const handleMouseLeave = useCallback(() => {
    setHover(false);
  }, []);
  const handleClear = useCallback(() => {
    setFieldValue(name, "");
  }, [setFieldValue, name]);
  const handleChange = useCallback(
    (event) => {
      const { value } = event.target;
      setFieldValue(name, value);
      onChange?.(value, event);
    },
    [setFieldValue, onChange, name]
  );
  return (
    <TextField
      fullWidth
      variant="outlined"
      name={name}
      value={value ?? ""}
      label={label}
      placeholder={placeholder}
      helperText={showError ? fieldError : helperText}
      error={showError}
      disabled={disabled ?? isSubmitting}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        ...(inputComponent && { inputComponent }),
        startAdornment: Icon ? (
          <InputAdornment position="start">
            <Icon color={showError ? "error" : "action"} fontSize="small" />
          </InputAdornment>
        ) : null,
        endAdornment: (
          <InputAdornment
            position="end"
            style={{
              visibility:
                (focus || hover) && !isEmpty(toString(value))
                  ? "visible"
                  : "hidden",
            }}
          >
            <Tooltip
              enterDelay={800}
              placement="bottom"
              title={t("core:form.textField.tooltip.clear")}
            >
              <IconButton onClick={handleClear} tabIndex={-1}>
                <ClearIcon color="action" fontSize="small" />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      }}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onChange={handleChange}
      {...props}
    />
  );
};

const FormTextField = (props) => {
  const { format, ...rest } = props;
  // Memo
  const inputComponent = useDeepMemo(() => {
    if (format?.type === "number") {
      return NumberFormat({ ...format });
    } else {
      return format;
    }
  }, [format]);
  // Render
  return (
    <FormField
      component={Component}
      inputComponent={inputComponent}
      {...rest}
    />
  );
};

export default FormTextField;
