// React
import React from "react";
import PropTypes from "prop-types";
// Helpers
import { merge, noop } from "@mefisto/utils";
// Components
import { Theme } from "theme/components";
import { emphasize, alpha, colors } from "ui/components";
import { useTranslate } from "localization/hooks";

const LightTheme = ({ theme, overrides = noop, ...props }) => {
  // Framework
  const { t } = useTranslate();
  // Render
  return (
    <Theme
      name="light"
      title={t("core:theme.light.title")}
      theme={merge(
        {
          palette: {
            type: "light",
            text: {
              primary: colors.blueGrey[900],
              secondary: colors.blueGrey[500],
            },
            grey: {
              ...colors.grey,
              100: "#f7f7f7",
            },
            info: {
              dark: "#4184a4",
            },
            action: {
              active: colors.blueGrey[800],
              selected: "rgba(0, 0, 0, 0.04)",
              hoverOpacity: 0.05,
            },
            error: colors.red,
            divider: "#ebebeb",
            background: {
              default: "#fff",
            },
          },
          typography: {
            h5: {
              fontWeight: 500,
            },
            subtitle2: {
              fontSize: "0.915rem",
              letterSpacing: "0.00914em",
            },
            caption: {
              fontWeight: 500,
              lineHeight: 1.2,
            },
          },
          shadows: [
            "none",
            "rgb(95 116 141 / 3%) 0px 2px 1px -1px, rgb(95 116 141 / 4%) 0px 1px 1px 0px, rgb(95 116 141 / 8%) 0px 1px 3px 0px",
            "rgb(95 116 141 / 3%) 0px 5px 10px -3px, rgb(95 116 141 / 4%) 0px 8px 18px 1px, rgb(95 116 141 / 8%) 0px 3px 33px 2px",
            "0px 11px 15px -7px rgba(0,0,0,0.06),0px 24px 38px 3px rgba(0,0,0,0.04),0px 9px 46px 8px rgba(0,0,0,0.06)",
            "0px 5px 5px -3px rgb(0 0 0 / 6%), 0px 8px 10px 1px rgb(0 0 0 / 6%), 0px 3px 14px 2px rgb(0 0 0 / 6%)",
            `#ebebeb 0 2px 0 1px`,
          ],
        },
        theme
      )}
      overrides={(theme) =>
        merge(
          {
            MuiCssBaseline: {
              "@global": {
                body: {
                  backgroundColor: theme.palette.background.default,
                  overflow: "hidden",
                },
              },
            },
            MuiAlert: {
              root: {
                lineHeight: "normal",
                borderRadius: theme.radius.large,
              },
              icon: {
                padding: 0,
                alignItems: "center",
              },
              standardSuccess: {
                border: theme.palette.border.surface.main,
                borderColor: alpha(theme.palette.success.main, 0.2),
              },
              standardInfo: {
                border: theme.palette.border.surface.main,
                borderColor: alpha(theme.palette.info.main, 0.2),
              },
              standardWarning: {
                border: theme.palette.border.surface.main,
                borderColor: alpha(theme.palette.warning.main, 0.2),
              },
              standardError: {
                border: theme.palette.border.surface.main,
                borderColor: alpha(theme.palette.error.main, 0.2),
              },
            },
            MuiAlertTitle: {
              root: {
                ...theme.typography.subtitle2,
                lineHeight: "normal",
                marginTop: theme.spacing(0.25),
              },
            },
            MuiPopover: {
              paper: {
                borderRadius: theme.radius.large,
                overflow: "hidden",
                border: theme.palette.border.surface.main,
                boxShadow: `${theme.palette.border.surface.color} 0 2px 0 1px`,
              },
            },
            MuiTouchRipple: {
              /**
               * Make the ripple effect less visible
               */
              rippleVisible: {
                opacity: 0.05,
              },
              "@keyframes enter": {
                "100%": {
                  transform: "scale(1)",
                  opacity: 0.05,
                },
              },
            },
            MuiAutocomplete: {
              paper: {
                boxShadow: "0 0 12px 7px rgb(0 0 0 / 0.05)",
                borderRadius: 4,
              },
            },
            MuiPaper: {
              /**
               * Don't round paper corners
               */
              rounded: {
                borderRadius: 0,
              },
            },
            MuiCardActionArea: {
              root: {
                /**
                 * Make the card action area focus state less visible
                 */
                "&$focusVisible $focusHighlight": {
                  opacity: theme.palette.action.hoverOpacity,
                },
              },
            },
            MuiFormLabel: {
              root: {
                color: theme.palette.text.primary,
                fontWeight: 500,
                fontSize: "1.02rem",
                letterSpacing: "0.02em",
              },
            },
            MuiFormControlLabel: {
              label: {
                ...theme.typography.body2,
                lineHeight: 1,
              },
            },
            MuiStepIcon: {
              root: {
                color: theme.palette.grey[400],
              },
            },
            MuiStepConnector: {
              line: {
                borderColor: theme.palette.grey[300],
              },
            },
            MuiStepLabel: {
              label: {
                ...theme.typography.subtitle2,
                color: theme.palette.grey[500],
              },
            },
            MuiButton: {
              root: {
                border: theme.palette.border.prominent.main,
                borderColor: "transparent",
              },
              label: {
                /**
                 * Don't transform button label
                 */
                textTransform: "none",
              },
              colorInherit: {
                borderColor: "transparent",
              },
              contained: {
                /**
                 * No shadow around contained buttons
                 */
                boxShadow: "none",
                backgroundColor: theme.palette.background.prominent.main,
                borderRadius: theme.radius.small,
                color: theme.palette.action.active,
                border: theme.palette.border.prominent.main,
                "&:hover": {
                  boxShadow: "none",
                  backgroundColor: theme.palette.background.prominent.hover,
                },
                "&$focusVisible": {
                  boxShadow: "none",
                },
                "&:active": {
                  boxShadow: "none",
                },
                "&:disabled": {
                  background: theme.palette.background.prominent.main,
                },
              },
              containedPrimary: {
                borderColor: "transparent",
              },
              containedSecondary: {
                borderColor: "transparent",
              },
              outlined: {
                textTransform: "capitalize",
                borderWidth: 2,
                borderColor: theme.palette.text.secondary,
                "&:hover": {
                  borderWidth: 2,
                },
                paddingTop: 4,
                paddingBottom: 4,
                "&$disabled": {
                  border: `2px solid ${theme.palette.grey[300]}`,
                },
              },
              outlinedPrimary: {
                borderWidth: 2,
                "&:hover": {
                  borderWidth: 2,
                },
                paddingTop: 4,
                paddingBottom: 4,
                "&$disabled": {
                  border: `2px solid ${theme.palette.grey[300]}`,
                },
              },
              outlinedSecondary: {
                borderWidth: 2,
                "&:hover": {
                  borderWidth: 2,
                },
                paddingTop: 4,
                paddingBottom: 4,
                "&$disabled": {
                  border: `2px solid ${theme.palette.grey[300]}`,
                },
              },
            },
            MuiIconButton: {
              root: {
                padding: theme.spacing(1),
              },
            },
            MuiBadge: {
              colorError: {
                backgroundColor: "#e45366",
              },
              badge: {
                boxShadow: "none",
              },
            },
            MuiChip: {
              sizeSmall: {
                height: theme.spacing(2.5),
              },
              labelSmall: {
                ...theme.typography.subtitle2,
                fontSize: theme.typography.pxToRem(12),
              },
              clickable: {
                "&:hover, &:focus": {
                  backgroundColor: emphasize(theme.palette.grey[300], 0.01),
                },
                "&:active": {
                  boxShadow: "none",
                  backgroundColor: emphasize(theme.palette.grey[300], 0.01),
                },
              },
              deletable: {
                "&:focus": {
                  backgroundColor: emphasize(theme.palette.grey[300], 0.01),
                },
              },
            },
            MuiFormControl: {
              root: {
                outline: 0,
              },
            },
            MuiOutlinedInput: {
              notchedOutline: {
                borderColor: theme.palette.border.surface.color,
                borderWidth: 2,
              },
            },
            MuiSnackbarContent: {
              root: {
                // Make the long text be inline with close button
                flexWrap: "nowrap",
              },
            },
            MuiTooltip: {
              tooltip: {
                color: theme.palette.common.white,
                backdropFilter: "blur(8px)",
                backgroundColor: alpha(theme.palette.common.black, 0.8),
                borderRadius: theme.radius.normal,
                fontSize: theme.typography.caption.fontSize,
                fontWeight: theme.typography.subtitle2.fontWeight,
                padding: theme.spacing(1, 1.25),
              },
            },
            MuiTab: {
              wrapper: {
                textTransform: "initial",
              },
              textColorSecondary: {
                color: theme.palette.action.active,
              },
            },
            MuiListItem: {
              button: {
                "&:hover": {
                  // Make the selection color lighter
                  backgroundColor: alpha(theme.palette.grey[500], 0.08),
                },
              },
            },
            MuiListItemText: {
              root: {
                overflowWrap: "break-word",
              },
            },
            MuiMenuItem: {
              root: {
                "&$selected": {
                  // Make the selection color lighter
                  backgroundColor: alpha(theme.palette.grey[500], 0.04),
                },
              },
            },
            MuiTableCell: {
              root: {
                borderBottom: "none",
                padding: theme.spacing(1, 2, 1, 3),
              },
            },
            MuiTableRow: {
              root: {
                "&$selected": {
                  backgroundColor: alpha(theme.palette.grey[500], 0.06),
                },
                "&$hover:hover": {
                  backgroundColor: alpha(theme.palette.grey[500], 0.04),
                },
              },
            },
            MuiDialog: {
              paper: {
                borderRadius: theme.radius.large,
                boxShadow: "0px 13px 40px 10px rgba(0, 0, 0, 0.07)",
              },
            },
            MuiDialogTitle: {
              root: {
                paddingBottom: theme.spacing(1),
              },
            },
            MuiDialogActions: {
              root: {
                margin: 0,
                padding: theme.spacing(2, 3, 3),
              },
            },
            MuiSelect: {
              icon: {
                color: "inherit",
              },
            },
            MuiInput: {
              root: {
                ...theme.typography.subtitle2,
                lineHeight: 1,
              },
              underline: {
                "&:before": {
                  borderBottomColor: "inherit",
                  borderBottomWidth: 2,
                },
                "&:after": {
                  borderBottomColor: "inherit",
                  borderBottomWidth: 3,
                },
                "&:focus": {
                  borderBottomColor: "inherit",
                  borderBottomWidth: 3,
                },
                "&:hover:before": {
                  borderBottomColor: "inherit !important",
                  borderBottomWidth: "3px !important",
                },
              },
            },
            MuiInputBase: {
              input: {
                paddingBottom: 6,
              },
            },
          },
          overrides(theme)
        )
      }
      {...props}
    />
  );
};

LightTheme.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  theme: PropTypes.object,
  overrides: PropTypes.func,
};

export default LightTheme;
