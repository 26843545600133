// React
import { createContext, useContext } from "react";

export const PixelContext = createContext({});

/**
 * @return {{ pixel: import("../Pixel").Pixel }}
 */
export const usePixel = () => {
  return useContext(PixelContext);
};
