// Framework
import { withStyles, AccordionActions } from "@material-ui/core";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

export default withStyles((theme) => ({
  root: {
    background: theme.palette.background.surface.main,
    border: theme.palette.border.surface.main,
    borderTop: "none",
    padding: theme.spacing(3),
    minHeight: 56,
  },
}))(AccordionActions);
