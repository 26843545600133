// React
import React, { forwardRef } from "react";
import PropTypes from "prop-types";
// Helpers
import { isString } from "@mefisto/utils";
// Framework
import {
  alpha,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Typography,
} from "ui/components";
import { makeStyles } from "@material-ui/core";
import { classnames } from "ui/classnames";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    border: theme.palette.border.spotlight.main,
    backgroundColor: theme.palette.background.spotlight.main,
    borderRadius: theme.radius.normal,
    transition: theme.transitions.create(["border", "color", "background"], {
      duration: theme.transitions.duration.shortest,
    }),
    "&:hover": {
      backgroundColor: theme.palette.background.spotlight.hover,
    },
  },
  selected: ({ color = theme.palette.info.main }) => ({
    backgroundColor: `${alpha(color, 0.3)} !important`,
    borderColor: `${alpha(color, 0.4)} !important`,
  }),
  avatar: {
    transition: theme.transitions.create(["opacity"], {
      duration: theme.transitions.duration.shortest,
    }),
  },
  avatarDisabled: {
    filter: "saturate(0.4)",
    opacity: 0.3,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const SpotlightButton = forwardRef(
  (
    {
      title,
      subtitle,
      avatar,
      color,
      selected,
      disabled,
      secondaryAction,
      titleTypographyProps,
      subtitleTypographyProps,
      onSelection,
    },
    ref
  ) => {
    // Styles
    const classes = useStyles({ color });
    // Render
    return (
      <ListItem
        button
        ref={ref}
        classes={{
          root: classes.root,
          selected: classes.selected,
        }}
        disabled={disabled}
        selected={selected}
        onClick={onSelection}
      >
        {avatar && (
          <ListItemAvatar
            className={classnames(classes.avatar, {
              [classes.avatarDisabled]: disabled,
            })}
          >
            {avatar}
          </ListItemAvatar>
        )}
        <ListItemText
          disableTypography
          primary={
            <>
              {isString(title) ? (
                <Typography
                  variant="subtitle2"
                  lineHeight="normal"
                  {...titleTypographyProps}
                >
                  {title}
                </Typography>
              ) : (
                title
              )}
            </>
          }
          secondary={
            <>
              {isString(subtitle) ? (
                <Typography
                  variant="body2"
                  lineHeight="normal"
                  {...subtitleTypographyProps}
                >
                  {subtitle}
                </Typography>
              ) : (
                subtitle
              )}
            </>
          }
        />
        {secondaryAction && (
          <ListItemSecondaryAction className={classes.secondaryAction}>
            {secondaryAction}
          </ListItemSecondaryAction>
        )}
      </ListItem>
    );
  }
);

SpotlightButton.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  avatar: PropTypes.node,
  color: PropTypes.string,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  secondaryAction: PropTypes.node,
  titleTypographyProps: PropTypes.object,
  subtitleTypographyProps: PropTypes.object,
  onSelection: PropTypes.func,
};

export default SpotlightButton;
