// Helpers
import { isNil } from "@mefisto/utils";
// Framework
import { StackDependency } from "stack/dependency";
// Components
import moment from "moment";
import "moment-timezone";

export class Time extends StackDependency {
  #database;
  #log;
  #offset = 0;
  #timezone = "UTC";

  onInitialized() {
    const { firebase, log } = this.context;
    this.#database = firebase.database();
    this.#log = log;
    this.#observeServerTimeOffset();
  }

  /**
   * Starts observing of the server time offset comparing to client time
   * @private
   */
  #observeServerTimeOffset = () => {
    this.#database.ref(".info/serverTimeOffset").on("value", (snapshot) => {
      this.#offset = snapshot.val();
      this.#log.info("⌛", this.#offset);
    });
  };

  /**
   * Localizer for calendar
   */
  localizer() {
    // Make sure the localizer is always going to show the local date
    const localMoment = (...args) => moment(...args).local();
    localMoment.localeData = moment.localeData;
    return localMoment;
  }

  /**
   * Sets locale
   * @param locale
   */
  async setLocale(locale) {
    if (locale !== "en") {
      try {
        await import("moment/locale/" + locale);
      } catch (e) {
        this.#log.error(e);
      }
    }
    moment.locale(locale);
  }

  /**
   * Sets timezone
   * @param timezone
   */
  setTimezone(timezone) {
    moment.tz.setDefault(timezone);
    this.#timezone = timezone;
  }

  /**
   * Returns list of all timezone names
   * @return {array}
   */
  timezones() {
    return moment.tz.names();
  }

  /**
   * Returns timezone detail
   * @param timezone
   * @return {*}
   */
  timezone(timezone) {
    return moment.tz(timezone);
  }

  /**
   * Returns moment instance parsed from the date in the given format
   * @param date
   * @param format
   * @param language
   * @return {moment.Moment}
   */
  fromFormat(date, format, language) {
    return moment(date, format, language);
  }

  /**
   * Returns moment instance parsed from the given timestamp.
   * @param timestamp
   * @param timezone If set, the resulting date instance will be in the
   * given timezone
   * @param clockTime If set to `true` the date instance will be set to the
   * UTC clock time value. In other words it will not be shifted based
   * on the timezone.
   * @return {moment.Moment}
   */
  fromUtcTimestamp(
    timestamp,
    { timezone = this.#timezone, clockTime = false } = {}
  ) {
    const utc = moment.utc(timestamp, "x", true);
    if (!isNil(timezone)) {
      return utc.tz(timezone, clockTime);
    } else {
      return utc.local(clockTime);
    }
  }

  /**
   * Returns moment instance parsed from the local timestamp
   * @param timestamp
   * @param timezone If set, the resulting date instance will be in the
   * given timezone
   * @param clockTime If set to `true` the date instance will be set to the
   * UTC clock time value. In other words it will not be shifted based
   * on the timezone.
   * @return {moment.Moment}
   */
  fromLocalTimestamp(
    timestamp,
    { timezone = this.#timezone, clockTime = false } = {}
  ) {
    const local = moment(timestamp, "x", true).local();
    if (!isNil(timezone)) {
      return local.tz(timezone, clockTime);
    } else {
      return local;
    }
  }

  /**
   * Return UTC timestamp value from the given date.
   * @param timestamp
   * @param clockTime If set to `true` the timestamp is provided in the
   * "clock time". Clock time is used when you don't care about the moment in
   * time the event occurs but rather you want it to happen when the clock
   * reaches given time at the given timezone. Whenever you change the
   * timezone the event timestamp changes as well.
   * @param format
   * @return {string}
   */
  toUtcTimestamp(timestamp, { clockTime = false } = {}) {
    return moment(timestamp, "x", true).utc(clockTime).format("x");
  }

  /**
   * Returns date that is at the beginning of the day
   * @param date
   * @return {moment.Moment}
   */
  day(date) {
    return date.clone().startOf("day");
  }

  /**
   * Returns moment instance with duration in milliseconds
   * @param ms
   * @return {*}
   */
  duration(ms) {
    return new moment.duration(ms);
  }

  /**
   * Returns time ago string from the given timestamp relative to `now`
   * @param timestamp
   * @param from
   * @param clockTime
   * @return {string}
   */
  timeAgo(timestamp, { from = this.now(), clockTime = false } = {}) {
    return from.to(this.fromUtcTimestamp(timestamp, { clockTime }));
  }

  /**
   * Returns moment instance with current time
   * @return {moment.Moment}
   */
  now() {
    return moment().add(this.#offset, "milliseconds");
  }

  /**
   * Returns moment instance with the beginning of the today
   * @return {moment.Moment}
   */
  today() {
    return this.now().startOf("day");
  }

  /**
   * Returns moment instance with the beginning of tomorrow
   * @return {moment.Moment}
   */
  tomorrow() {
    return this.today().add(1, "day");
  }

  /**
   * Returns moment instance with the beginning of yesterday
   * @return {moment.Moment}
   */
  yesterday() {
    return this.today().subtract(1, "day");
  }
  /**
   * Returns an array of weekday names.
   * @param {Object} options - Optional parameters.
   * @param {boolean} [options.short=false] - If true, returns abbreviated weekday names.
   * @returns {string[]} - An array of weekday names (full or short).
   */
  weekdays({ short = false } = {}) {
    return short ? moment.weekdaysShort() : moment.weekdays();
  }

  /**
   * Returns the ISO weekday number for a given day.
   * @param {number} day - The ISO weekday number (1 = Monday, 7 = Sunday).
   * @returns {moment.Moment} - A moment object representing the specified weekday.
   */
  weekDayOf(day) {
    return moment().isoWeekday(day);
  }

  /**
   * Returns `true` if the given time is today
   * @param timestamp
   * @return {boolean}
   */
  isToday(timestamp) {
    return moment(timestamp, "x", true).startOf("day").isSame(this.today());
  }

  /**
   * Returns `true` if the given timestamp is today or in the future.
   * @param timestamp
   * @return {boolean}
   */
  isTodayOrAfter(timestamp) {
    return moment(timestamp, "x", true)
      .startOf("day")
      .isSameOrAfter(this.today());
  }

  /**
   * Returns `true` if the given timestamp is tomorrow or in the future
   * @param timestamp
   * @return {boolean}
   */
  isTomorrowOrAfter(timestamp) {
    return moment(timestamp, "x", true)
      .startOf("day")
      .isSameOrAfter(this.tomorrow());
  }

  /**
   * Returns `true` if the given timestamp is today or in the past.
   * @param timestamp
   * @return {boolean}
   */
  isTodayOrBefore(timestamp) {
    return moment(timestamp, "x", true)
      .startOf("day")
      .isSameOrBefore(this.today());
  }

  /**
   * Returns `true` if the given timestamp is yesterday or in the past.
   * @param timestamp
   * @return {boolean}
   */
  isYesterdayOrBefore(timestamp) {
    return moment(timestamp, "x", true)
      .startOf("day")
      .isSameOrBefore(this.yesterday());
  }
}
