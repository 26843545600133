// React
import React, { forwardRef } from "react";
import PropTypes from "prop-types";
// Framework
import { classnames } from "ui/classnames";
import { makeStyles } from "@material-ui/core";
import { Skeleton as Component } from "@material-ui/lab";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.surface.main,
  },
  dark: {
    backgroundColor: theme.palette.background.prominent.main,
  },
  border: {
    border: theme.palette.border.surface.main,
  },
  rounded: {
    borderRadius: theme.radius.normal,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const Skeleton = forwardRef(
  (
    {
      children,
      display,
      variant,
      border,
      animated,
      animation,
      borderRadius,
      ...props
    },
    ref
  ) => {
    // Styles
    const classes = useStyles();
    // Render
    return (
      <Component
        ref={ref}
        animation={animated ? animation : false}
        variant={variant === "rounded" ? "rect" : variant}
        {...props}
        className={classnames(props.className, classes.root, {
          [classes.dark]: display === "dark",
          [classes.rounded]: variant === "rounded",
          [classes.border]: border,
        })}
        style={{
          ...props.style,
          borderRadius,
        }}
      >
        {children}
      </Component>
    );
  }
);

Skeleton.propTypes = {
  /**
   * Whether the skeleton is displayed on dark or light background.
   */
  display: PropTypes.oneOf(["dark", "light"]),
  /**
   * Set to `true` in order to animate the skeleton.
   */
  animated: PropTypes.bool,
  /**
   * Whether the skeleton should have a border
   */
  border: PropTypes.bool,
  /**
   * The animation.
   * If `false` the animation effect is disabled.
   */
  animation: PropTypes.oneOf(["pulse", "wave"]),
  /**
   * Optional children to infer width and height from.
   */
  children: PropTypes.node,
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object,
  /**
   * The component used for the root node.
   * Either a string to use an HTML element or a component.
   */
  component: PropTypes.any,
  /**
   * Height of the skeleton.
   * Useful when you don't want to adapt the skeleton to a text element but for instance a card.
   */
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * The type of content that will be rendered.
   */
  variant: PropTypes.oneOf(["text", "rect", "circle", "rounded"]),
  /**
   * Width of the skeleton.
   * Useful when the skeleton is inside an inline element with no width of its own.
   */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Skeleton;
