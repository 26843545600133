// React
import React from "react";
import PropTypes from "prop-types";
// Helpers
import { isString } from "@mefisto/utils";
// Components
import { classnames } from "ui/classnames";
import { makeStyles, TableCell, Typography } from "@material-ui/core";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    border: theme.palette.border.surface.main,
    borderColor: "transparent",
    borderLeft: "none",
    borderRight: "none",
    transition: theme.transitions.create(["background"], {
      duration: theme.transitions.duration.short,
    }),
  },
  selected: {
    borderColor: theme.palette.border.surface.color,
  },
  highlighted: {
    borderColor: theme.palette.border.surface.color,
  },
  sticky: {
    position: "sticky",
    whiteSpace: "nowrap",
    // A small trick to size the cell based on the content
    width: 1,
    right: 0,
    background: "inherit",
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const TableRowItem = ({
  variant,
  align,
  size,
  wrap,
  sticky,
  children,
  __renderProps: { selected, highlighted } = {},
}) => {
  // Styles
  const classes = useStyles();
  // Render
  return (
    <TableCell
      align={align}
      size={size}
      className={classnames(classes.root, {
        [classes.sticky]: sticky,
        [classes.selected]: selected,
        [classes.highlighted]: highlighted,
      })}
    >
      {isString(children) ? (
        <Typography
          component="div"
          noWrap={!wrap}
          variant={variant === "highlight" ? "subtitle2" : "inherit"}
        >
          {children}
        </Typography>
      ) : (
        children
      )}
    </TableCell>
  );
};

TableRowItem.propTypes = {
  /**
   * Specify the item type
   */
  variant: PropTypes.oneOf(["default", "highlight"]),
  /**
   * Set content align
   */
  align: PropTypes.oneOf(["center", "inherit", "justify", "left", "right"]),
  /**
   * Specify the size of the item.
   */
  size: PropTypes.oneOf(["medium", "small"]),
  /**
   * Set to `true` when content should wrap
   */
  wrap: PropTypes.bool,
  /**
   * Set to `true` when the table cell suppose to be sticky
   */
  sticky: PropTypes.bool,
  /**
   * The row item contents
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default TableRowItem;
