// React
import React, {
  Children,
  cloneElement,
  isValidElement,
  useCallback,
  useMemo,
} from "react";
import PropTypes from "prop-types";
// Helpers
import { compact, includes, first, size } from "@mefisto/utils";
// Framework
import { usePortal } from "stack/core";
import { useLocation } from "navigation";
import { makeStyles, Section, Spacer, Cloak, Grid, Tabs } from "ui";
// Components
import CoverButtons from "./components/CoverButtons";
import CoverOptions from "./components/CoverOptions";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.common.white,
    border: theme.palette.border.surface.main,
    borderTop: "none",
    padding: theme.spacing(0, 1),
  },
  section: {
    marginBottom: `${theme.spacing(-0.5) - 1}px`,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const CoverTabs = ({
  context = "tabs",
  value,
  buttons,
  options,
  defaultTab,
  disableTabCloak,
  children,
}) => {
  // Framework
  const {
    hash: { tab = defaultTab },
  } = useLocation();
  const { navigation } = usePortal();
  // Styles
  const classes = useStyles();
  // Memo
  const tabs = useMemo(() => {
    return compact(
      Children.map(
        children,
        (child) => isValidElement(child) && child.props.value
      )
    );
  }, [children]);
  const currentTab = useMemo(() => {
    return includes(tabs, tab) ? tab : first(tabs);
  }, [tab, tabs]);
  const enableCloak = useMemo(() => {
    return disableTabCloak || size(tabs) === 1;
  }, [disableTabCloak, tabs]);
  // Callbacks
  const handleChange = useCallback(
    (event, tab) => {
      navigation.setHash({
        params: { tab },
      });
    },
    [navigation]
  );
  // Render
  return (
    <Section context={context} value={value}>
      <div className={classes.root}>
        <Grid container spacing={1} className={classes.section}>
          <Grid item>
            <Cloak enabled={enableCloak}>
              <Tabs
                value={currentTab}
                indicatorColor="primary"
                textColor="primary"
                TabIndicatorProps={{
                  style: { transition: "none" },
                }}
                onChange={handleChange}
              >
                {Children.map(
                  children,
                  (tab) =>
                    isValidElement(tab) &&
                    cloneElement(tab, {
                      __display: {
                        render: "tabs",
                      },
                    })
                )}
              </Tabs>
            </Cloak>
          </Grid>
          <Spacer />
          {buttons && (
            <Grid item>
              <CoverButtons buttons={buttons} />
            </Grid>
          )}
          {options && (
            <Grid item>
              <CoverOptions options={options} />
            </Grid>
          )}
        </Grid>
      </div>
      {Children.map(
        children,
        (element) =>
          isValidElement(element) &&
          cloneElement(element, {
            __display: {
              render: "content",
              currentTab,
            },
          })
      )}
    </Section>
  );
};

CoverTabs.propTypes = {
  context: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.array,
  buttons: PropTypes.array,
  disableTabCloak: PropTypes.bool,
  defaultTab: PropTypes.string,
  children: PropTypes.node,
};

export default CoverTabs;
