// React
import React, { useMemo, forwardRef } from "react";
import PropTypes from "prop-types";
// Framework
import {
  classnames,
  makeStyles,
  useMediaQuery,
  Section,
  Grid,
  Spacer,
  Typography,
} from "ui";
import { useTheme } from "theme";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
  },
  inset: {
    paddingLeft: theme.spacing(5),
  },
  disableGutter: {
    paddingBottom: 0,
  },
  appendix: {
    height: "100%",
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const SceneTitle = forwardRef(
  (
    {
      size = "medium",
      context = "title",
      value,
      title,
      description,
      image,
      inset,
      appendix,
      disableGutter,
    },
    ref
  ) => {
    // Styles
    const classes = useStyles();
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down("xs"));
    // Memo
    const titleVariant = useMemo(() => {
      switch (size) {
        case "small":
          return "subtitle2";
        case "medium":
          return "h6";
        case "large":
          return "h5";
      }
    }, [size]);
    // Render
    return (
      <Section context={context} value={value}>
        <div
          ref={ref}
          className={classnames(
            classes.root,
            disableGutter && classes.disableGutter
          )}
        >
          <Grid
            container
            spacing={3}
            alignItems="flex-end"
            wrap={xs ? "wrap" : "nowrap"}
          >
            <Grid item>
              <Grid container wrap="nowrap" spacing={2}>
                {image && <Grid item>{image}</Grid>}
                <Grid item>
                  <Grid container direction="column">
                    {title && (
                      <Grid item>
                        <Typography variant={titleVariant} component="div">
                          {title}
                        </Typography>
                      </Grid>
                    )}
                    {description && (
                      <Grid item className={classnames(inset && classes.inset)}>
                        <Typography
                          variant="subtitle2"
                          color="textSecondary"
                          component="div"
                        >
                          {description}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {appendix && (
              <>
                {!xs && <Spacer />}
                <Grid flex={xs} item>
                  {appendix}
                </Grid>
              </>
            )}
          </Grid>
        </div>
      </Section>
    );
  }
);

SceneTitle.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  context: PropTypes.string,
  value: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  image: PropTypes.node,
  inset: PropTypes.bool,
  appendix: PropTypes.node,
  disableGutter: PropTypes.bool,
};

export default SceneTitle;
