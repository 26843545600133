// React
import React, { memo, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
// Framework
import { useAction } from "hooks";
import { useEntityRead } from "model/hooks";
import { EntityPropType } from "model/utils";
import { makeStyles, classnames, Section, Fade, IconButton } from "ui";
import { Avatar } from "storage";
import { PhotoCameraTwoTone as UpdateIcon } from "icon/material";
// Components
import ModelProfileImageDialog from "./components/ModelProfileImageDialog";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  button: {
    width: 40,
    height: 40,
    position: "absolute",
    right: 0,
    bottom: 0,
    border: `2px solid ${theme.palette.common.white}`,
    borderRadius: theme.radius.rounded,
    background: theme.palette.grey[200],
    "&:hover": {
      background: theme.palette.grey[300],
    },
  },
  buttonSmall: {
    width: 30,
    height: 30,
    right: 0,
    bottom: theme.spacing(-1),
    fontSize: "1rem",
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ModelProfileImage = ({
  context = "profileImage",
  value,
  actions,
  entity: Entity,
  input,
  resources,
  languages,
  tags,
  fetchPolicy,
  size = 180,
  titleName = "name",
  fileName = "profileImage",
}) => {
  // Framework
  const { a } = useAction(actions);
  // Styles
  const classes = useStyles();
  // State
  const [showUpdateButton, setShowUpdateButton] = useState(false);
  // Model
  const { data } = useEntityRead(Entity, {
    input,
    resources,
    languages,
    fetchPolicy,
    tags,
  });
  // Ref
  const dialogRef = useRef(null);
  // Callbacks
  const handleMouseOver = useCallback(() => {
    setShowUpdateButton(a("update"));
  }, [a]);
  const handleMouseOut = useCallback(() => {
    setShowUpdateButton(false);
  }, []);
  const handleUpdate = useCallback(() => {
    dialogRef.current.open("update", {
      input,
      resources,
      overrides: {
        [fileName]: null,
      },
      silent: true,
      optimisticUI: false,
    });
  }, [input, resources, fileName]);
  // Render
  return (
    <Section context={context} value={value}>
      <ModelProfileImageDialog
        ref={dialogRef}
        entity={Entity}
        fileName={fileName}
      />
      <Avatar
        display="highlight"
        image={data[fileName]}
        title={data[titleName]}
        size={size}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseOut}
      >
        <Fade in={showUpdateButton} unmountOnExit>
          <IconButton
            value="update"
            className={classnames(classes.button, {
              [classes.buttonSmall]: size < 100,
            })}
            onClick={handleUpdate}
          >
            <UpdateIcon color="inherit" fontSize="inherit" />
          </IconButton>
        </Fade>
      </Avatar>
    </Section>
  );
};

ModelProfileImage.propTypes = {
  context: PropTypes.string,
  value: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.oneOf(["update", false])),
  entity: EntityPropType,
  input: PropTypes.object,
  resources: PropTypes.object,
  languages: PropTypes.array,
  tags: PropTypes.any,
  size: PropTypes.number,
  fetchPolicy: PropTypes.string,
  titleName: PropTypes.string,
  fileName: PropTypes.string,
};

export default memo(ModelProfileImage);
