// React
import React from "react";
import PropTypes from "prop-types";
// Helpers
import { map } from "@mefisto/utils";
// Framework
import { makeStyles, Grid, Button } from "ui/components";
import { useTranslate } from "localization/hooks";
// Components
import FeedSkeletons from "../FeedSkeletons";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles(() => ({
  loadMore: {
    display: "flex",
    justifyContent: "center",
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const FeedIncrementalList = ({
  items,
  spacing,
  skeleton,
  skeletonCount,
  hasMore,
  disableLoadMore,
  loadingMore,
  onLoadMore,
}) => {
  // Styles
  const classes = useStyles();
  // Framework
  const { t } = useTranslate();
  // Render
  return (
    <Grid container direction="column" wrap="nowrap" spacing={spacing}>
      {map(items, (item, key) => (
        <Grid item key={key}>
          {item}
        </Grid>
      ))}
      {!disableLoadMore && (
        <>
          {((hasMore && !loadingMore) || (loadingMore && !skeleton)) && (
            <Grid item>
              <div className={classes.loadMore}>
                <Button
                  variant="text"
                  disabled={loadingMore}
                  onClick={onLoadMore}
                >
                  {t("core:feed.button.loadMore.title")}
                </Button>
              </div>
            </Grid>
          )}
          {loadingMore && skeleton && (
            <FeedSkeletons skeleton={skeleton} skeletonCount={skeletonCount} />
          )}
        </>
      )}
    </Grid>
  );
};

FeedIncrementalList.propTypes = {
  items: PropTypes.any,
  spacing: PropTypes.number,
  skeleton: PropTypes.node,
  skeletonCount: PropTypes.number,
  disableLoadMore: PropTypes.bool,
  hasMore: PropTypes.bool,
  loadingMore: PropTypes.bool,
  onLoadMore: PropTypes.func,
};

export default FeedIncrementalList;
