// React
import React, { forwardRef } from "react";
import PropTypes from "prop-types";
// Framework
import {
  makeStyles,
  alpha,
  classnames,
  Box,
  Cloak,
  Button,
  IconButton,
} from "ui";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  button: {
    height: 40,
  },
  iconButton: {
    transition: theme.transitions.create(["color", "border", "background"]),
    border: theme.palette.border.prominent.main,
    borderRadius: theme.radius.small,
    "&:hover": {
      border: theme.palette.border.prominent.main,
      backgroundColor: theme.palette.background.prominent.hover,
    },
    "&:focus": {
      border: theme.palette.border.prominent.main,
      backgroundColor: theme.palette.background.prominent.hover,
    },
  },
  iconButtonRegular: {
    color: theme.palette.action.active,
    backgroundColor: theme.palette.background.prominent.main,
  },
  iconButtonSelected: {
    color: theme.palette.primary.main,
    borderColor: `${alpha(theme.palette.primary.main, 0.2)} !important`,
    backgroundColor: `${alpha(theme.palette.primary.main, 0.2)} !important`,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const CoverTabsButton = forwardRef(
  (
    {
      context,
      value,
      title,
      icon,
      color,
      selected,
      loading,
      onClick,
      ...props
    },
    ref
  ) => {
    // Styles
    const classes = useStyles();
    // Render
    return (
      <div ref={ref}>
        {icon && !title && (
          <IconButton
            context={context}
            value={value}
            color="inherit"
            selected={selected}
            className={classnames(classes.button, classes.iconButton, {
              [classes.iconButtonRegular]: !selected,
              [classes.iconButtonSelected]: selected,
            })}
            onClick={onClick}
            {...props}
          >
            {icon}
          </IconButton>
        )}
        {title && (
          <Button
            context={context}
            value={value}
            color={color}
            size="large"
            variant="contained"
            className={classes.button}
            startIcon={icon}
            loading={loading}
            onClick={onClick}
            {...props}
          >
            {title}
          </Button>
        )}
        <Cloak enabled={!selected}>
          <Box width="100%" height={2} mt={0.75} bgcolor="primary.main" />
        </Cloak>
      </div>
    );
  }
);

CoverTabsButton.propTypes = {
  context: PropTypes.string,
  value: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.node,
  color: PropTypes.node,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default CoverTabsButton;
