// React
import React, {
  isValidElement,
  cloneElement,
  useCallback,
  forwardRef,
} from "react";
import PropTypes from "prop-types";
// Helpers
import { isUndefined } from "@mefisto/utils";
// Framework
import {
  alpha,
  makeStyles,
  classnames,
  Cloak,
  Button,
  TooltipButton,
  Divider,
  ListItemIcon,
  Grid,
  ListItemText,
  Box,
  MenuItem,
} from "ui";
import { useTheme } from "theme";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(0, 2),
    color: theme.palette.action.active,
    borderColor: alpha(theme.palette.action.active, 0.6),
    background: theme.palette.common.white,
  },
  icon: {
    color: theme.palette.action.active,
  },
  horizontalDivider: {
    margin: theme.spacing(1, 0),
  },
  verticalDivider: {
    minHeight: theme.spacing(3),
    margin: theme.spacing(0, 1),
    width: 2,
  },
  selected: {
    color: theme.palette.primary.main,
  },
  underline: {
    width: "100%",
    height: 2,
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(-0.5),
    background: theme.palette.primary.main,
  },
  gutter: {
    margin: theme.spacing(0, 1),
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const SceneTabsOption = forwardRef(
  (
    {
      context,
      value,
      type = "icon",
      title,
      subtitle,
      color,
      icon,
      gutter,
      disabled,
      selected,
      component,
      onSelection,
      onClose,
      __subtype,
    },
    ref
  ) => {
    // Styles
    const classes = useStyles();
    const { radius } = useTheme();
    // Handlers
    const handleSelection = useCallback(() => {
      onClose?.();
      onSelection?.();
    }, [onSelection, onClose]);
    // Render
    return (
      <div ref={ref} className={classnames({ [classes.gutter]: gutter })}>
        {component ?? (
          <>
            {type === "divider" && (
              <Divider
                variant="middle"
                orientation={__subtype === "menu" ? "horizontal" : "vertical"}
                className={classnames({
                  [classes.horizontalDivider]: __subtype === "menu",
                  [classes.verticalDivider]: __subtype === "standalone",
                })}
              />
            )}
            {type === "icon" && (
              <TooltipButton
                context={context}
                value={value}
                tooltip={title}
                disabled={disabled}
                className={classnames(classes.icon, {
                  [classes.selected]: selected,
                })}
                onClick={onSelection}
              >
                {isValidElement(icon) &&
                  cloneElement(icon, {
                    color: "inherit",
                  })}
              </TooltipButton>
            )}
            {type === "button" && (
              <Button
                context={context}
                value={value}
                variant="outlined"
                disabled={disabled}
                className={classes.button}
                onClick={onSelection}
              >
                {title}
              </Button>
            )}
            {type === "menu" && (
              <MenuItem
                dense
                context={context}
                value={value}
                onClick={handleSelection}
              >
                {icon && (
                  <ListItemIcon>
                    {cloneElement(icon, { fontSize: "small", color })}
                  </ListItemIcon>
                )}
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <ListItemText
                      primary={title}
                      secondary={subtitle}
                      color={color}
                      primaryTypographyProps={{ color }}
                      secondaryTypographyProps={{ color }}
                    />
                  </Grid>
                  {!isUndefined(selected) && (
                    <Grid item>
                      <Cloak enabled={!selected}>
                        <Box
                          width={8}
                          height={8}
                          borderRadius={radius.rounded}
                          bgcolor="info.main"
                        />
                      </Cloak>
                    </Grid>
                  )}
                </Grid>
              </MenuItem>
            )}
            {__subtype === "standalone" && (
              <Cloak enabled={!selected}>
                <div className={classes.underline} />
              </Cloak>
            )}
          </>
        )}
      </div>
    );
  }
);

SceneTabsOption.propTypes = {
  type: PropTypes.oneOf(["icon", "button", "divider", "menu"]),
  title: PropTypes.string,
  icon: PropTypes.element,
  disabled: PropTypes.bool,
  gutter: PropTypes.bool,
  selected: PropTypes.bool,
  component: PropTypes.node,
  divider: PropTypes.bool,
  onSelection: PropTypes.func,
};

export default SceneTabsOption;
