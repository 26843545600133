// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { makeStyles, classnames, AppBar } from "ui";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: ({ backgroundColor, textColor, shadow, border }) => ({
    appRegion: "drag",
    color: textColor,
    background: backgroundColor,
    boxShadow: shadow ?? theme.shadows[0],
    border: border ?? theme.palette.border.surface.main,
    borderTop: "none",
  }),
  rounded: {
    borderRadius: `0 0 ${theme.radius.large}px ${theme.radius.large}px`,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const HeaderBar = ({
  display,
  position,
  textColor,
  backgroundColor,
  shadow,
  border,
  children,
}) => {
  // Styles
  const classes = useStyles({
    textColor,
    backgroundColor,
    shadow,
    border,
  });
  // Render
  return (
    <AppBar
      color="inherit"
      position={position}
      className={classnames(classes.root, {
        [classes.rounded]: display === "rounded",
      })}
    >
      {children}
    </AppBar>
  );
};

HeaderBar.propTypes = {
  position: PropTypes.string,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  shadow: PropTypes.string,
  children: PropTypes.node,
};

export default HeaderBar;
