// React
import React, { isValidElement, cloneElement, useCallback } from "react";
import PropTypes from "prop-types";
// Framework
import { makeStyles, Typography, Button, TooltipButton } from "ui/components";
// Components
import { useTableContext } from "../TableContext";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(0, 2),
    color: theme.palette.action.active,
    background: theme.palette.background.default,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const TableRowOption = ({
  type = "icon",
  title,
  icon,
  disabled,
  onSelection,
  __renderProps: { index },
}) => {
  // Styles
  const classes = useStyles();
  // Context
  const { data } = useTableContext();
  // Callbacks
  const handleClick = useCallback(
    (event) => {
      event.stopPropagation();
      onSelection(data[index], index);
    },
    [onSelection, data, index]
  );
  // Render
  return (
    <>
      {type === "icon" && (
        <Typography color="textSecondary" component="div">
          <TooltipButton
            color="inherit"
            tooltip={title}
            disabled={disabled}
            onClick={handleClick}
          >
            {isValidElement(icon) && cloneElement(icon, { color: "action" })}
          </TooltipButton>
        </Typography>
      )}
      {type === "button" && (
        <Button
          variant="outlined"
          disabled={disabled}
          className={classes.button}
          onClick={handleClick}
        >
          {title}
        </Button>
      )}
    </>
  );
};

TableRowOption.propTypes = {
  type: PropTypes.oneOf(["icon", "button"]),
  title: PropTypes.string,
  icon: PropTypes.element,
  disabled: PropTypes.bool,
  onSelection: PropTypes.func,
  onClose: PropTypes.func,
};

export default TableRowOption;
