// React
import React, {
  Children,
  useState,
  useCallback,
  useEffect,
  useMemo,
  isValidElement,
  cloneElement,
} from "react";
import PropTypes from "prop-types";
// Helpers
import { isEmpty, map } from "@mefisto/utils";
// Framework
import { classnames } from "ui/classnames";
import { makeStyles, TableCell } from "ui/components";
import { TableRow as TableRowComponent } from "@material-ui/core";
// Components
import TableRowOptions from "./components/TableRowOptions";
import { useTableContext } from "../TableContext";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    height: 56,
    backgroundColor: theme.palette.background.default,
    borderColor: "transparent",
  },
  highlighted: {
    borderColor: theme.palette.border.surface.color,
    backgroundColor: theme.palette.background.surface.main,
  },
  selected: {
    borderColor: theme.palette.border.surface.color,
    backgroundColor: theme.palette.background.surface.main,
  },
  selectable: {
    cursor: "pointer",
  },
  badge: {
    position: "sticky",
    left: 0,
    padding: 0,
    borderLeft: "6px solid",
    borderRadius: `0 ${theme.radius.normal} ${theme.radius.normal} 0`,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const TableRow = ({
  selectable,
  selected,
  badge,
  options,
  children,
  onSelection,
  __renderProps: { index },
}) => {
  // Styles
  const classes = useStyles();
  // Context
  const { data, setPrepend, setAppend } = useTableContext();
  // State
  const [highlighted, setHighlighted] = useState(false);
  // Memo
  const hasOptions = useMemo(() => {
    return !isEmpty(options);
  }, [options]);
  // Effect
  useEffect(() => {
    if (badge) {
      setPrepend(true);
    }
    if (hasOptions) {
      setAppend(true);
    }
  }, [setAppend, setPrepend, badge, hasOptions]);
  // Callbacks
  const handleClick = useCallback(() => {
    selectable && onSelection && onSelection(data[index], index);
  }, [selectable, onSelection, data, index]);
  const handleMouseOver = useCallback(() => {
    if (selectable || hasOptions) {
      setHighlighted(true);
    }
  }, [selectable, hasOptions]);
  const handleMouseLeave = useCallback(() => {
    if (selectable || hasOptions) {
      setHighlighted(false);
    }
  }, [selectable, hasOptions]);
  // Render
  return (
    <TableRowComponent
      tabIndex={-1}
      className={classnames(classes.root, {
        [classes.highlighted]: highlighted,
        [classes.selected]: selected,
        [classes.selectable]: selectable,
      })}
      onClick={handleClick}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      {badge && (
        <TableCell
          className={classes.badge}
          style={{ borderLeftColor: badge }}
        />
      )}
      {Children.map(
        children,
        (child, index) =>
          isValidElement(child) &&
          cloneElement(child, {
            __renderProps: { index, selected, highlighted },
          })
      )}
      {options && (
        <TableRowOptions
          index={index}
          highlighted={highlighted}
          options={options}
        />
      )}
    </TableRowComponent>
  );
};

TableRow.propTypes = {
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  badge: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.node),
  children: PropTypes.node,
  onSelection: PropTypes.func,
};

export default TableRow;
