// Framework
import { withStyles, AccordionSummary } from "@material-ui/core";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

export default withStyles((theme) => ({
  root: {
    minHeight: theme.spacing(7),
    border: theme.palette.border.surface.main,
    borderLeftWidth: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    background: theme.palette.common.white,
    boxShadow: theme.shadows[0],
    transition: "transform .2s",
    "&:hover": {
      transform: "scale(1.017)",
    },
    "&$expanded": {
      minHeight: theme.spacing(7),
      transform: "scale(1)",
    },
  },
  content: {
    "&$expanded": {
      margin: theme.spacing(1.5, 0),
    },
  },
  expanded: {},
}))(AccordionSummary);
